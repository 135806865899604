import React from "react";
import AgreedHomeComponent from "../component/OurWork/agreedHomeComponent";
import ChallengeComponent from '../component/OurWork/challengeComponent';
import SolutionsComponent from "../component/OurWork/solutionsComponent";
import Footer from '../component/footerComponent';
import QuoteComponent from "../component/OurWork/quoteComponent";
import ResultsComponent from "../component/OurWork/resultsComponent";
import { graphql } from 'gatsby';
import Layout from "../component/layout";
const  ParamountAcceptance = (props) =>{
    const{
        data:{
          wpPage:{
            seo,
            projectChallengeSection :{
                projectChallengeDesignDescription,
                projectChallengeDesignTitle,
                projectChallengeDevelopmentDescription,
                projectChallengeDevelopmentTitle,
                projectChallengeFeatureDescription,
                projectChallengeFeatureTitle,
                projectChallengeTitle,
                projectChallengeImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectChallengeImage
                    },
                  },
                },
              },
              projectQuoteSection :{
                projectQuoteDescription
              },
              projectResultsSection :{
                projectResultsDescription,
                projectResultsTitle,
                projectResultsImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImage
                    },
                  },
                },
                projectResultsImageSecond :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImageSecond
                    },
                  },
                },
                projectResultsImageThird :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImageThird
                    },
                  },
                },
              },
              projectSolutionsSection :{
                projectSolutionsNewFeaturesDescription,
                projectSolutionsNewFeaturesTitle,
                projectSolutionsTitle,
                projectSolutionsUserExperienceDescription,
                projectSolutionsUserExperienceTitle,
                projectSolutionsWebApplicationDescription,
                projectSolutionsWebApplicationTitle,
              },
              agreedHomeSection :{
                agreedHomeData,
                agreedHomeDescription,
                agreedHomeLocation,
                agreedHomeTitle,
                agreedHomeImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:agreedHomeImage
                    },
                  },
                },
              },
            },
        },
        pageContext: { title },
    } = props;
    return(
        <Layout title={title} seo={seo}>
        <AgreedHomeComponent
        agreedHomeTitle={agreedHomeTitle}
        agreedCalendarText={agreedHomeData}
        agreedLocationText={agreedHomeLocation}
        agreedHomeDescription={agreedHomeDescription}
        projectImage={agreedHomeImage}
        />
        <ChallengeComponent
        challengeDescription={projectChallengeDevelopmentDescription}
        challengeText={projectChallengeFeatureDescription}
        challengeTitle={projectChallengeTitle}
        challengeInneficient={projectChallengeDevelopmentTitle}
        challengeComplicated={projectChallengeDesignTitle}
        challengeNecessary={projectChallengeFeatureTitle}
        challengeComplicatedText={projectChallengeDesignDescription}
        projectChallengeImage={projectChallengeImage}
        />
        <SolutionsComponent
        // solutionsTitle={projectSolutionsTitle}
        // userExperienceTitle={projectSolutionsUserExperienceTitle}
        // userExperienceDescription={projectSolutionsUserExperienceDescription}
        // addFeaturesTitle={projectSolutionsUserExperienceTitle}
        // addFeaturesDescription={projectSolutionsUserExperienceDescription}
        // // addFeaturesTitle={projectSolutionsUserExperienceTitle}
        // // addFeaturesDescription={projectSolutionsUserExperienceDescription}
        // isParamount={true}
        // isWorkPage={true}
        // isProject={true}
        solutionsTitle={projectSolutionsTitle}
         userExperienceTitle={projectSolutionsUserExperienceTitle}
         userExperienceDescription={projectSolutionsUserExperienceDescription}
         racutenTitle={projectSolutionsWebApplicationTitle}
         racutenDescription={projectSolutionsWebApplicationDescription}
         addFeaturesTitle={projectSolutionsNewFeaturesTitle}
         addFeaturesDescription={projectSolutionsNewFeaturesDescription}
         isProject={true}
         isWorkPage={true}
         isParamount={true}
         isRacuten={true}
        />
        <QuoteComponent
        quoteText={projectQuoteDescription}
        />
        <ResultsComponent
        resultsTitle={projectResultsTitle}
        resultsDescription={projectResultsDescription}
        projectFirstImage={projectResultsImage}
        projectSecondImage={projectResultsImageSecond}
        projectThirdImage={projectResultsImageThird}
        />
        <Footer/>
        </Layout>
    )
}
export const query = graphql`
  query {
    wpPage(slug: {eq: "paramount-acceptance-page"}) {
      seo {
        metaDesc
        title
      }
        projectChallengeSection {
          projectChallengeDesignDescription
          projectChallengeDesignTitle
          projectChallengeDevelopmentDescription
          projectChallengeDevelopmentTitle
          projectChallengeFeatureDescription
          projectChallengeFeatureTitle
          projectChallengeTitle
          projectChallengeImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        projectQuoteSection {
          projectQuoteDescription
        }
        projectResultsSection {
          projectResultsDescription
          projectResultsTitle
          projectResultsImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          projectResultsImageSecond {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          projectResultsImageThird {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        projectSolutionsSection {
          projectSolutionsNewFeaturesDescription
          projectSolutionsNewFeaturesTitle
          projectSolutionsNextLevelDescription
          projectSolutionsNextLevelTitle
          projectSolutionsTitle
          projectSolutionsUserExperienceDescription
          projectSolutionsUserExperienceTitle
          projectSolutionsWebApplicationDescription
          projectSolutionsWebApplicationTitle
        }
        agreedHomeSection {
          agreedHomeData
          agreedHomeDescription
          agreedHomeLocation
          agreedHomeTitle
          agreedHomeImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
  }
  `;
export default ParamountAcceptance